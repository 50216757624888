/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://wgs4loczwfhatbn46ckrwtkyzu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "pcoverifypbcjwtapi",
            "endpoint": "https://9ftemwwpr1.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:a34e03de-36e6-4294-9910-fe3af815da11",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tEaorE9OY",
    "aws_user_pools_web_client_id": "6d1joqtpkdtrnq02nkmirl5uvi",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Configuration-production",
            "region": "us-east-1"
        },
        {
            "tableName": "Reporting-production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "pco-card-art113826-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
